import React from "react"

const Footer = ({ siteData, contactData }) => {
  const date = new Date()
  return (
    <footer className="footer-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-footer-widget">
              <h3>{siteData.company_name}</h3>
              <p className="text-white">
                Our Mission and Vision is to provide compassionate, accessible,
                high quality, cost effective healthcare to the community and to
                promote health.
              </p>

              {/* <ul className="social">
                <li>
                  <a href="#">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
              </ul> */}
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-footer-widget">
              <h3>Quick Links</h3>

              <ul className="footer-quick-links">
                <li>
                  <a href="/downloads">Reports and Downloads</a>
                </li>
                <li>
                  <a href="/general_meetings">General Meetings</a>
                </li>
                <li>
                  <a href="/about">About Nemcare Tezpur</a>
                </li>
                <li>
                  <a href="http://nemcare.com/#">
                    Nemcare Heart Institute &amp; Research Center
                  </a>
                </li>
                <li>
                  <a href="https://nemcarehospital.in/">
                    Nemcare Hospital (MultiSpeciality)
                  </a>
                </li>
                <li>
                  <a href="https://ngiguwahati.in/">
                    Nemcare Group of Institutions
                  </a>
                </li>
                <li>
                  <a href="http://burncarefoundation.org.in/">
                    Burn care Foundation
                  </a>
                </li>
                {/* <li>
                  <a href="#">Privacy Policy</a>
                </li> */}
                <li>
                  <a href="https://www.mohfw.gov.in/">Ministry of Health</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-sm-3 offset-md-3">
            <div className="single-footer-widget">
              <h3>Contact Info</h3>

              <ul className="footer-contact-info">
                <li>
                  <span>Location:</span> {contactData.address}
                </li>
                <li>
                  <span>Email:</span> <a href="#">{contactData.email}</a>
                </li>
                <li>
                  <span>Phone:</span> <a href="#">{contactData.mobile}</a>
                </li>
                {/*
                <li>
                  <span>Fax:</span>{" "}
                  <a href="#">{contactData.fax || contactData.phone}</a>
                </li>
                <li><a href="https://goo.gl/maps/1xz4L8TGSdkBhVmb7" target="_blank">View Location on GoogleMap</a></li> */}
              </ul>
            </div>
          </div>
        </div>

        <div className="copyright-area" style={{ marginTop: "0px" }}>
          <div className="row align-items-center">
            <div className="col-lg-6 col-sm-6 col-md-6">
              <p>
                <i className="far fa-copyright"></i> {date.getFullYear()}{" "}
                {siteData.company_name}
              </p>
            </div>

            <div className="col-lg-6 col-sm-6 col-md-6">
              <ul>
                {/* <li><a href="#">Privacy Policy</a></li> */}
                <li>
                  Developed by{" "}
                  <a href="https://itechcom.net">Itechcom - ITECH COMPUTER</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
